import React from 'react'

function ModalA({setModalOpen}) {
  return (
    <div className="game-modal open">
        <div className="modal-inner">
            <button className="close-modal" onClick={()=>setModalOpen(false)}>
                <img src="/icon-close.svg" alt="close icon"/>
            </button>
            <iframe title="The Game" src="https://socialgameno.com/game" width="100%" height="800" id="gameFrame"><br />
                Your browser does not support the element <code>iframe</code>.<br />
            </iframe>
        </div>
    </div>
  )
}

export default ModalA